import { browser } from '$app/env';
import { supabase } from '$lib/data/supabase';
import { app_version } from '$lib/utils';
import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";

if (browser) {
  Sentry.init({
    dsn: "https://c7db33633fe846f7b098ab86c12b288e@o234654.ingest.sentry.io/5766286",
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    release: app_version(),
    environment: process.env.NODE_ENV,
  });
  set_sentry_user();
}

export function set_sentry_user() {
  const session = supabase.auth.session();
  if (session) {
    Sentry.setUser({ id: session.user?.email });
  } else {
    Sentry.setUser(null);
  }
}