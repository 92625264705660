import cookie from 'cookie'
import type { Session } from '@supabase/supabase-js';
import { config } from '$lib/config';

const SESSION_KEY = config.api.session_cookie_key;

export function parse_session_cookie(request) {
  return cookie.parse(request.headers.cookie || '')[SESSION_KEY] || null;
}

export function set_client_cookie(session: Session) {
  const session_cookie = `${SESSION_KEY}=${session.access_token}; Path=/; Secure; SameSite=Strict; Expires=${new Date(session.expires_at * 1000).toUTCString()};`;
  document.cookie = session_cookie;
}

export function unset_client_cookie() {
  const session_cookie = `${SESSION_KEY}=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT;`
  document.cookie = session_cookie;
}